<template>
  <v-card class="card-shadows border" color="white">
    <v-card-title v-if="successMessage" class="success-sec-btn mb-2">
      {{ successMessage }}
    </v-card-title>
    <v-card-title v-if="errorMessage" class="error-sec-btn mb-2">
      {{ errorMessage }}
    </v-card-title>

    <v-row align="end" class="pa-8 " style="gap: 30px">
      <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details
        class="search-field" />

      <v-btn color="primary" @click.prevent="currentPage = 1; UserList(search)">Search</v-btn>
      <!-- <v-menu offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="primary" v-bind="attrs" v-on="on">
            {{sortByText}}: <v-icon>mdi-menu-down</v-icon>
          </v-btn>
        </template>
<v-list>
  <v-list-item v-for="(option, index) in sortOptions" :key="index" @click="handleSort(option)">
    <v-list-item-title>{{ option }}</v-list-item-title>
  </v-list-item>
</v-list>
</v-menu> -->
    </v-row>
    <v-data-table :headers="headers" :items="sortedTableData" hide-default-footer :loading="loading"
      :loading-text="message" :items-per-page="currentRows">
      <template v-slot:item.action="{ item }">
        <v-flex style="display: flex;">
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small outlined color="primary" dark v-bind="attrs" v-on="on">
                {{ item.action }} <v-icon>mdi-chevron-down</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item link
                @click='handleRedirect(item.user_id)'><v-list-title>Business</v-list-title></v-list-item>
              <v-list-item link
                @click='handleRedirectStaff(item.user_id)'><v-list-title>Staff</v-list-title></v-list-item>
              <v-list-item link @click='handleChangePartyGroup(item)'>
                <v-list-title>Party Group</v-list-title>
              </v-list-item>
              <v-list-item v-if="unlockUser && item.block" link
                @click='handleRedirectUserUnlock(item.email)'><v-list-title>Unlock
                  User</v-list-title></v-list-item>
              <v-list-item v-if="resetPass" link @click='userPass(item)'>
                <v-list-title>Change Password</v-list-title>
              </v-list-item>
              <v-list-item v-if="!item.block" link @click='handleLockUser(item.email)'>
                <v-list-title>Lock User</v-list-title>
              </v-list-item>


            </v-list>
          </v-menu>
          <v-btn small outlined color="primary" style="margin-left: 15px;" @click="handleModal(item)">
            <v-icon>mdi-pencil-outline</v-icon>
          </v-btn>
        </v-flex>
      </template>
      <template v-slot:header>
        <thead>
          <tr>
            <th v-for="header in headers" :key="header.text"
              :class="{ 'text--primary': header.text === 'Name' || header.text === 'Email' || header.text === 'Phone', 'font-weight-bold text-decoration-underline': header.text === 'Name' || header.text === 'Email' || header.text === 'Phone' }"
              @click="toggleSort(header.value)">
              {{ header.text }}

              <v-icon small v-if="sortBy === header.value">{{ sortDesc ? 'mdi-arrow-down' : 'mdi-arrow-up' }}</v-icon>
            </th>
          </tr>
        </thead>
      </template>
      <!-- </v-data-table> -->
      <!-- <template v-slot:header="{ props }">
        <thead>
          <tr>
            <th v-for="header in props.headers" :key="header.text" :class="{ 'text--primary': header.text === 'Name' || header.text === 'Email' || header.text === 'Phone' }">
              <span v-if="header.text === 'Name' || header.text === 'Email' || header.text === 'Phone'" class="font-weight-bold text-decoration-underline">{{ header.text }}</span>
              <span v-else>{{ header.text }}</span>
            </th>
          </tr>
        </thead>
      </template> -->
    </v-data-table>
    <v-row align="center" justify="end">
      <v-col cols="auto">
        <v-select style="width: 60px;" :items="rowsPerPage" @change="handleChangeRows" v-model="currentRows" />
      </v-col>
      <v-col cols="auto" style="min-width: 400px;">
        <v-pagination @input="pageChange" color="black" v-model="currentPage" :length="noOfPages" :total-visible="7" />
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialog" persistent max-width="650px">
        <v-card v-if="dialogFor === 'password'">
          <v-card-title>
            <span class="text-h5">User Profile</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="password" :type="show1 ? 'text' : 'password'"
                    :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show1 = !show1" label="Password*"
                    required />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field v-model="password_confirmation" :type="show2 ? 'text' : 'password'"
                    :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'" @click:append="show2 = !show2"
                    label="Conform Password*" required />
                </v-col>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="blue darken-1" text @click="dialog = false">
              Close
            </v-btn>
            <v-btn color="blue darken-1" text @click="changePassword(userID)">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-else>
          <v-card-title>
            <span class="text-h5">User Profile</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <!-- <v-col cols="12" sm="6">
                  <v-text-field label="First Name" v-model="uFirstName" required />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field label="Last Name" v-model="uLastName" required />
                </v-col> -->
                <v-col cols="12" sm="6">
                  <v-text-field label="Email" v-model="uEmail" required />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field label="Phone" v-model="uPhone" required :rules="numberRule" />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field label="Address 1" v-model="uAdd1" required />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field label="Address 2" v-model="uAdd2" required />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field label="City" v-model="uCity" required />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field label="State" v-model="uState" required />
                </v-col>
                <v-col cols="12" sm="4">
                  <v-text-field label="Country" v-model="uCountry" required />
                </v-col>
                <v-col cols="12" sm="6">
                  <v-text-field label="Zip code" v-model="uZip_code" required />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="error" text @click="dialog = false">
              Close
            </v-btn>
            <v-btn color="success" @click.prevent="editUser()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-row justify="center">
      <v-dialog v-model="dialogForGroup" persistent max-width="650px">
        <v-card>
          <v-card-title>
            <span class="text-h5">Update Party Group</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <!-- <v-text-field label="Party Group" v-model="newGroup" required :rules="numberRule" />l  -->
                  <!-- <v-select :items="partyGroups" v-model="currentRows" /> -->
                  <v-select :items="groups" label="Group" v-model="newGroup" />
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="error" text @click="dialogForGroup = false">
              Close
            </v-btn>
            <v-btn color="success" @click="updatePartyGroup()">
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
    <v-snackbar v-model="snackbar" :timeout="timeout" color="primary" outlined>
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-card>
</template>

<script>

export default {
  data() {
    var permissions = localStorage.getItem('permissions')

    var hasChangePartyGroupPermission = false;
    var tHeaders = [
      { text: 'Name', align: 'start', value: 'name', },
      { text: 'Customer Group', value: 'customerGroup' }
    ]

    var unlockUser = false;
    var resetPass = false;

    if (permissions === 'ALL' || permissions.includes(1)) {
      hasChangePartyGroupPermission = true;
      // tHeaders.push({ text: 'Customer Group', value: 'customerGroup' });
    }

    if (permissions === 'ALL' || permissions.includes(2)) {
      unlockUser = true;
    }

    if (permissions === 'ALL' || permissions.includes(3)) {
      resetPass = true;
    }

    tHeaders = [
      ...tHeaders,
      { text: 'Email', value: 'email', },
      { text: 'Phone', value: 'phone' },
      { text: 'Address', value: 'address' },
      { text: 'KYC Status', value: 'kyc_status' },
      { text: 'Action', value: 'action' },
    ]
    return {
      currentPage: 1,
      noOfPages: 1,

      rowsPerPage: [10, 15, 30, 50],
      currentRows: 10,
      successMessage: '',
      errorMessage: '',

      tableData: [],
      data: '',
      message: 'Loading... Please wait',
      loading: true,
      search: '',
      sortBy: null,
      sortDesc: false,
      sortByText: 'Sort By',
      headers: tHeaders,
      hasChangePartyGroupPermission: hasChangePartyGroupPermission,
      unlockUser: unlockUser,
      userID: '',
      show1: false,
      show2: false,
      resetPass: resetPass,
      // modalData: null,
      abortController: new AbortController(),

      // sortOptions: ['Name', 'Email', 'Customer Group', 'Address', 'Phone Number', 'KYC Status'],

      //this is for update user 
      dialog: false,
      uId: '',
      uFirstName: '',
      uLastName: '',
      uEmail: '',
      uPhone: '',
      uAdd1: '',
      uAdd2: '',
      uCity: '',
      uState: '',
      uCountry: '',
      uZip_code: '',
      numberRule: [
        (v) => /^[0-9]+$/.test(v) || 'Only numbers are allowed',
      ],

      dialogForGroup: '',
      groups: [2, 21, 17, 22],
      newGroup: '',
    };
  },
  props: {
    currentServer: Object,
  },
  computed: {
    sortedTableData() {
      if (!this.sortBy) {
        return this.tableData;
      }
      return this.tableData.slice().sort((a, b) => {
        const fieldA = a[this.sortBy];
        const fieldB = b[this.sortBy];
        let comparison = 0;
        if (fieldA < fieldB) {
          comparison = -1;
        } else if (fieldA > fieldB) {
          comparison = 1;
        }
        return this.sortDesc ? comparison * -1 : comparison;
      });
    }
  },
  methods: {
    handleRedirect(index) {
      var environment = '/'
      const serverType = localStorage.getItem('serverType')
      if (serverType == 'staging') {
        environment = '/staging/'
      } else if (serverType == 'livestaging') {
        environment = '/live-staging/'
      }else if (serverType == 'local') {
        environment = '/local/'
      }
      console.log(index);
      this.$router.push(`/skyfi-dashboard${environment}business/${index}`);
    },
    userPass(item) {
      this.dialogFor = 'password'
      this.password = ''
      this.password_confirmation = ''
      this.userID = item?.user_id
      this.dialog = true
    },
    toggleSort(column) {
      if (this.sortBy === column) {
        // If clicking on the same column, toggle sort order
        this.sortDesc = !this.sortDesc;
      } else {
        // If clicking on a different column, set new sort column and default to ASC order
        this.sortBy = column;
        this.sortDesc = false;
      }
      // Call method to fetch data based on new sort settings
      this.UserList(this.search);
    },
    //sorting acc. to ascending order
    // handleSort(option) {
    //   switch (option) {
    //     case 'Name':
    //       this.sortBy = 'name';
    //       break;
    //     case 'Customer Group':
    //       this.sortBy = 'customerGroup';
    //       break;
    //     case 'Email':
    //       this.sortBy = 'email';
    //       break;
    //     case 'Phone':
    //       this.sortBy = 'phone';
    //       break;
    //     case 'Address':
    //       this.sortBy = 'address';
    //       break;
    //     case 'KYC Status':
    //       this.sortBy = 'kyc_status';
    //       break;
    //     default:
    //       break;
    //   }
    //   this.sortBy = option.toLowerCase().replace(/\s+/g, '_');
    //   this.sortByText = option; 
    //   this.sortDesc = false;
    //   this.UserList(this.search);
    // },
    async handleRedirectUserUnlock(email) {
      try {
        // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl
        const baseurl = this.currentServer?.baseUrl
        var myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');

        myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);
        const response = await fetch(`${baseurl}admin/unlock_account`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({
            'email': email,
          }),
        })
        const data = await response.json()
        this.data = JSON.stringify(response)
        if (response.status === 200) {
          this.successMessage = 'User unlocked successfully'
          setTimeout(() => {
            this.successMessage = '';
          }, 8000);

        }
        else {
          alert(data.error)
          this.errorMessage = data.error;
          setTimeout(() => {
            this.errorMessage = '';
          }, 8000);
        }
      } catch (error) {
        this.errorMessage = 'Unauthenticated';
        setTimeout(() => {
          this.errorMessage = ''
        }, 8000);
        this.$router.push('/admin-login');
      }
    },
    async handleLockUser(email) {
      try {
        this.loading = true

        // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl
        const baseurl = this.currentServer?.baseUrl
        var myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');

        myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);
        const response = await fetch(`${baseurl}admin/lock_user`, {
          method: 'PATCH',
          headers: myHeaders,
          body: JSON.stringify({
            'email': email,
          }),
        })
        const data = await response.json()
        this.data = JSON.stringify(response)
        this.loading = false
        if (response.status === 200) {
          this.successMessage = 'User locked successfully'
          setTimeout(() => {
            this.successMessage = '';
          }, 8000);

        }
        else {
          alert(data.error)
          this.errorMessage = data.error;
          setTimeout(() => {
            this.errorMessage = '';
          }, 8000);
        }
      } catch (error) {
        this.loading = false
        this.errorMessage = 'Unauthenticated';
        setTimeout(() => {
          this.errorMessage = ''
        }, 8000);
        this.$router.push('/admin-login');
      }
    },

    handleRedirectStaff(id) {
      const serverType = localStorage.getItem('serverType')
      if (serverType == 'staging') {
        this.$router.push(`/skyfi-dashboard/staging/staffs/${id}?for=user`);
      } else if (serverType == 'livestaging') {
        this.$router.push(`/skyfi-dashboard/live-staging/staffs/${id}?for=user`);
      } else {
        this.$router.push(`/skyfi-dashboard/staffs/${id}?for=user`);
      }
    },
    checkNull(value) {
      if (value === null || value === 'null' || value === '' || value === undefined) {
        return ''
      } else return value
    },
    async UserList(searchString) {
      try {

        this.abortController.abort(); // Cancel previous requests
        this.abortController = new AbortController(); // Create new AbortController
        const signal = this.abortController.signal;

        this.loading = true
        this.message = 'Loading... Please wait'
        const v_page = '?page=' + this.currentPage;
        const v_per_page = '&per_page=' + this.currentRows;
        const SearchString = searchString ? '&search_string=' + searchString : '';
        //sorting acc. to ascending order
        const sort = this.sortBy ? `&sort=${this.sortBy}` : '';
        const order = this.sortDesc ? '&order=desc' : '&order=asc';

        // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl
        const baseurl = this.currentServer?.baseUrl
        var myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);
        const response = await fetch(`${baseurl}admin/users${v_page + v_per_page + SearchString + sort + order}`, {
          method: 'GET',
          'Accept': 'application/json',
          headers: myHeaders,
          'Content-Type': 'application/json',
          'redirect': 'follow',
          signal
        })

        if (!response.ok) {
          if (response.status == '401') {
            throw new Error('Unauthorized');
          } else {
            throw new Error('Network response was not ok');
          }
        }
        const data = await response.json();
        // Handle successful login here
        const TableData = data?.data?.map(item => ({
          id: item?.id,
          email: item?.attributes?.email,
          name: this.checkNull(item.attributes.first_name) + ' ' + this.checkNull(item.attributes.last_name),
          first_name: item?.attributes?.first_name,
          last_name: item?.attributes?.last_name,
          phone: item?.attributes?.phone,
          user_id: item?.id,
          address: (item?.attributes?.address_1 ? item?.attributes?.address_1 : '') + (item?.attributes?.address_2 ? ', ' + item?.attributes?.address_2 : '') + (item?.attributes?.city ? ', ' + item?.attributes?.city : '') + (item?.attributes?.state ? ', ' + item?.attributes?.state : '') + (item?.attributes?.country ? ', ' + item?.attributes?.country : ''),
          address1: item?.attributes?.address_1,
          address2: item?.attributes?.address_2,
          customerGroup: item?.attributes?.customerGroup,
          city: item?.attributes?.city,
          state: item?.attributes?.state,
          country: item?.attributes?.country,
          block: item?.attributes?.block,
          zip_code: item?.attributes?.zip_code,
          kyc_status: item?.attributes?.kyc_status === null ? 'Pending' : item?.attributes?.kyc_status,
          action: 'Actions'
        }));

        // this.data = JSON.stringify(TableData);
        this.tableData = TableData;
        this.noOfPages = Math.ceil(parseInt(data?.total_count) / this.currentRows)
        this.loading = false
        this.message = ''

      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.name === 'AbortError') {
          // Request was cancelled
          this.loading = true
          this.message = 'Loading... Please wait'
        } else if (error.message.toUpperCase() === 'Unauthorized'.toUpperCase()) {
          this.$router.push('/admin-login');
        } else {
          this.loading = false
          this.message = 'something went wrong'
        }
        this.loading = false
        this.message = 'something went wrong'
      }
    },
    async changePassword(id) {
      try {
        this.btnLoading = true;
        // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl
        const baseurl = this.currentServer?.baseUrl
        var myHeaders = new Headers();
        myHeaders.append('Content-Type', 'application/json');
        myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);
        const response = await fetch(`${baseurl}api/v2/staffs/${id}/change_staff_password`, {
          method: 'POST',
          headers: myHeaders,
          body: JSON.stringify({
            'password': this.password,
            'password_confirmation': this.password_confirmation
          }),
          'redirect': 'follow',
        })
        if (!response.ok) {
          if (response.status == '401') {
            throw new Error('Unauthorized');
          } else if (response.status == '422') {
            throw new Error('Password and confirmation do not match or Password does not meet strength requirements. Use capital letters, special characters, and numbers');
          } else {
            throw new Error('Network response was not ok');
          }
        }
        const data = await response.json();
        this.btnLoading = false;
        this.UserList();
        this.dialog = false;
        this.snackbarText = data?.success;
        this.snackbar = true;
      } catch (error) {
        this.btnLoading = false;
        this.dialog = false;
        this.snackbarText = error.message;
        this.snackbar = true;
        if (error.message.toUpperCase() === 'Unauthorized'.toUpperCase()) {
          this.$router.push('/admin-login');
        }
        // return error.message
      }
    },
    updateSortBy(sortBy) {
      this.sortBy = sortBy;
      this.UserList(this.search);
    },
    //sorting acc. to ascending order
    sortByOption(option) {
      this.sortBy = option.toLowerCase().replace(/\s+/g, '_');
      this.sortDesc = false;
    },
    updateSortDesc(sortDesc) {
      this.sortDesc = sortDesc;
      this.UserList(this.search);
    },
    pageChange() {
      this.UserList(this.search);
    },
    handleChangeRows() {
      this.currentPage = 1
      this.UserList(this.search);
    },
    handleModal(data) {
      this.uId = data?.id,
        this.uFirstName = data?.first_name,
        this.uLastName = data?.last_name,
        this.uEmail = data?.email,
        this.uPhone = data?.phone,
        this.uAdd1 = data?.address1,
        this.uAdd2 = data?.address2,
        this.uCity = data?.city,
        this.uState = data?.state,
        this.uCountry = data?.country,
        this.uZip_code = data?.zip_code,
        this.dialog = true;
    },
    handleChangePartyGroup(data) {
      this.uId = data.id;
      this.groups = this.groups.filter((item) => item !== data.customerGroup)
      this.dialogForGroup = true;
    },
    async editUser() {
      // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl;
      const baseurl = this.currentServer?.baseUrl
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);
      const requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify({
          first_name: this.uFirstName,
          last_name: this.uLastName,
          email: this.uEmail,
          phone: this.uPhone,
          address_1: this.uAdd1,
          address_2: this.uAdd2,
          city: this.uCity,
          state: this.uState,
          country: this.uCountry,
          kyb_status: this.uKyb_status,
          zip_code: this.uZip_code,
        }),
        redirect: 'follow'
      }
      try {
        const response = await fetch(baseurl + `admin/users/${this.uId}`, requestOptions);
        if (!response.ok) {
          if (response.status == '401') {
            throw new Error('Unauthorized');
          } else {
            throw new Error('Network response was not ok');
          }
        }
        const data = await response.text();
        console.log(data)
        this.UserList();
        this.dialog = false
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.message.toUpperCase() === 'Unauthorized'.toUpperCase()) {
          this.$router.push('/admin-login');
        } else {
          alert('something went wrong')
          this.dialog = false
        }
      }
    },
    async updatePartyGroup() {
      // alert(JSON.stringify({
      //     'user_id': this.uId,
      //     'newGroupId': this.newGroup.toString()
      //   }))
      // const baseurl = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_BASE_URL_LOCAL : this.currentServer.baseUrl;
      const baseurl = this.currentServer?.baseUrl
      const myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      myHeaders.append('Authorization', `Bearer ${localStorage.getItem('token')}`);
      const requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: JSON.stringify({
          'user_id': this.uId,
          'newGroupId': this.newGroup.toString()
        }),
        redirect: 'follow'
      }
      try {
        const response = await fetch(baseurl + 'admin/update_party_group', requestOptions);
        if (!response.ok) {
          if (response.status == '401') {
            throw new Error('Unauthorized');
          } else {
            throw new Error('Network response was not ok');
          }
        }
        const data = await response.text();
        console.log(data)
        this.UserList();
        this.groups = [2, 21, 17, 22]
        this.dialogForGroup = false
      } catch (error) {
        console.error('Error fetching data:', error);
        if (error.message.toUpperCase() === 'Unauthorized'.toUpperCase()) {
          this.$router.push('/admin-login');
        } else {
          alert('something went wrong')
          this.groups = [2, 21, 17, 22]
          this.dialogForGroup = false
        }
      }
    },

  },

  mounted() {
    this.UserList();
  },
};
</script>

<style>
.card-shadows .v-data-table-header {
  display: none !important;
}
</style>